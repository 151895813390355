<template>
    <div id="stickers-page">
        <toolbar-component :title="$t('stickers_page.asking_stickers')" />

        <v-tabs v-model="tab_selected" background-color="secondary" class="default-arrows-tabs" show-arrows slider-color="primary">
            <v-tab
                :class="(tab_selected === 'asking-stickers' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
                href="#asking-stickers"
                v-if="userHasAccess('PWA_STICKERS', this.role_edit)"
            >
                {{ $t('stickers_page.new_order') }}
            </v-tab>

            <v-tab
                :class="(tab_selected === 'sticker-history' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
                href="#sticker-history"
            >
                {{ $t('stickers_page.history') }}
            </v-tab>

            <v-tab-item class="background" value="asking-stickers">
                <asking-stickers-component />
            </v-tab-item>

            <v-tab-item class="background pa-4" value="sticker-history">
                <stickers-history-component />
            </v-tab-item>
        </v-tabs>

        <v-btn
            v-if="userHasAccess('PWA_STICKERS', role_edit) && tab_selected === 'sticker-history'"
            :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
            color="secondary"
            elevation="0"
            fab
            @click="tab_selected = 'asking-stickers'"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import ToolbarComponent from '../../components/Global/ToolbarComponent'
import StickersHistoryComponent from '@/components/Sticker/StickersHistoryComponent.vue'
import AskingStickersComponent from '@/components/Sticker/AskingStickers/AskingStickersComponent.vue'

export default {
    name: 'AskingStickersPage',
    components: { AskingStickersComponent, StickersHistoryComponent, ToolbarComponent },
    data() {
        return {
            tab_selected: this.userHasAccess('PWA_STICKERS', this.role_edit) ? 'asking-stickers': 'sticker-history',
        }
    },
}
</script>
