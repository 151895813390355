import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IReferentielSticker } from '@/models/IReferentielSticker'

const resource = 'api/v1/referential_sticker'

export default {
    getReferentielStickerByIdProfessional(): Promise<AxiosResponse<IServerResponse<IReferentielSticker[]>>> {
        return axios.get(`${resource}/professional`)
    },
}
