<template>
    <div id="item-sticker-component">
        <v-list-item class="px-0 sticker-list-item">
            <v-img :src="referentiel_sticker.path_image" max-width="80px">
                <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="primary" indeterminate />
                    </v-row>
                </template>
            </v-img>

            <v-list-item-action-text>
                <div :class="referentiel_sticker.total_in_stock !== 0 ? 'ml-2 sticker-list-text' : 'ml-2 out-sticker-list-text'">
                    <span v-if="max_orderable !== 3" class="font-12">Format</span><br />
                    <span class="font-weight-bold font-12">{{ referentiel_sticker.label }}</span>
                    <br />
                    <span class="font-12 font-color-light">{{ referentiel_sticker.description }}</span>
                </div>
            </v-list-item-action-text>

            <v-list-item-action>
                <div v-if="referentiel_sticker.total_in_stock === 0" class="sticker-stock-alert">
                    <v-alert class="pa-2 my-auto" text type="error">
                        <template v-slot:prepend>
                            <v-icon></v-icon>
                        </template>

                        <div class="text-center">
                            <div class="pb-1">
                                <v-icon color="error"> mdi-alert-circle-outline</v-icon>
                            </div>
                            <p class="error--text font-12 mb-0 line-height-16">
                                Stock momentanément <br /> indisponible
                            </p>
                        </div>
                    </v-alert>
                </div>

                <v-row v-else class="ma-0 nb-sticker-module mr-2">
                    <div class="my-auto manage_nb_sticker_module">
                        <v-btn
                            :disabled="order_referentiel_sticker.total_ordered === 0"
                            class="pa-0 disabled minus-sticker-btn"
                            color="white"
                            elevation="0"
                            tile
                            @click="
                                order_referentiel_sticker.total_ordered -= 1
                                fixInput()
                            "
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <v-btn
                            :disabled="
                                order_referentiel_sticker.total_ordered >= this.max_orderable ||
                                order_referentiel_sticker.total_ordered >= referentiel_sticker.total_in_stock
                            "
                            class="pa-0 disabled plus-sticker-btn"
                            color="white"
                            elevation="0"
                            tile
                            @click="
                                order_referentiel_sticker.total_ordered += 1
                                fixInput()
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>

                        <v-text-field
                            v-model.number="order_referentiel_sticker.total_ordered"
                            :class="
                                order_referentiel_sticker.total_ordered !== 0
                                    ? 'elevation-0 background-white text-center mx-8 center-input nb-sticker-text-field'
                                    : 'elevation-0 light-grey-background-text-field text-center mx-8 center-input nb-sticker-text-field grey'
                            "
                            hide-details
                            solo
                            @change="fixInput()"
                        />
                    </div>
                </v-row>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
export default {
    name: 'ItemStickerComponent',
    props: {
        referentiel_sticker: {
            type: Object,
            required: true,
        },
        max_orderable: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            order_referentiel_sticker: {
                id_referentiel_sticker: this.referentiel_sticker.id_referentiel_sticker,
                total_ordered: 0,
            },
        }
    },
    methods: {
        fixInput() {
            let v = this.order_referentiel_sticker.total_ordered
            if (!(!isNaN(parseFloat(v)) && v >= 0)) {
                this.order_referentiel_sticker.total_ordered = 0
            } else if (v > this.max_orderable) {
                this.order_referentiel_sticker.total_ordered = this.max_orderable
            }
        },
    },
    watch: {
        'order_referentiel_sticker.total_ordered'(value) {
            if (!isNaN(parseFloat(value)) && value >= 0 && value <= this.max_orderable) {
                this.$emit('updateOrderReferentielSticker', this.order_referentiel_sticker)
            }
        },
    },
}
</script>

<style>
.center-input .v-text-field__slot input {
    text-align: center !important;
}

.sticker-list-item .v-list-item {
    height: 100px !important;
}

.nb-sticker-module .v-input__slot {
    padding-right: 8px !important;
    border-radius: unset !important;
}

/*
.nb-sticker-text-field {
    width: 40px !important;
    right: 50px;
    margin-left: 35px!important;
    margin-right: 50px!important;
}
*/

.nb-sticker-text-field {
    width: 40px !important;
    right: 50px !important;
    position: relative;

    margin-left: 35px !important;
    margin-right: 50px !important;
}

.nb-sticker-text-field .v-input__control {
    min-height: 56px !important;
    box-shadow: none !important;
}

.nb-sticker-text-field .v-input__control .v-input__slot {
    box-shadow: none !important;
    border: 1px solid #c0c2c5;
}

.plus-sticker-btn {
    min-width: 30px !important;
    height: 56px !important;
    position: absolute !important;
    right: 20px !important;
}

.minus-sticker-btn {
    min-width: 30px !important;
    height: 56px !important;
    position: absolute !important;
    left: -80px !important;
}

.manage_nb_sticker_module {
    position: absolute !important;
    right: -10px !important;
    top: 50% !important;
    transform: translate(0, -50%);
}

.sticker-stock-alert {
    position: absolute !important;
    right: 10px !important;
    bottom: 0px !important;
}

.out-sticker-list-text {
    margin-right: 110px;
}

.sticker-list-text {
    margin-right: 80px;
}

.light-grey-background-text-field .v-input__slot {
    background: #d7dade !important;
}
</style>
