<template>
    <div id="list-sticker-component">
        <div class="font-14">
            <p class="font-color-medium mb-2">
                {{ $t('stickers_page.dress_up_vehicles_with_stickers') }}
            </p>
            <p class="font-color-medium mb-2">
                Veuillez renseigner le nombre de stickers souhaité par modèle (envoi sous réserve de disponibilité).
            </p>
            <p class="font-color-medium mb-4">
                En raison de stocks limités, merci de ne demander que les unités correspondant à votre besoin précis.
            </p>
        </div>

        <v-row class="mx-0 mt-2 mb-4">
            <p class="mr-16 pr-10 mt-2 font-14">Combien de véhicules souhaitez-vous équiper de stickers Oleo100&thinsp;? *</p>

            <v-text-field v-model="total_vehicle_concerned" class="white-text-field select-vehicle" filled @change="fixInput()" />
        </v-row>

        <p class="font-weight-bold">Choix de stickers</p>

        <template v-if="!loading_referential_stickers">
            <template v-for="referentiel_sticker in referentiel_stickers">
                <list-item-sticker-component
                    v-if="referentiel_sticker.category === 'sticker'"
                    :key="referentiel_sticker.id_referentiel_sticker"
                    :max_orderable="99"
                    :referentiel_sticker="referentiel_sticker"
                    v-on:updateOrderReferentielSticker="updateOrderReferentielSticker"
                />

                <v-divider
                    v-if="referentiel_sticker.category === 'sticker'"
                    :key="referentiel_sticker.uid"
                    class="mt-3 mb-3"
                    color="white"
                />
            </template>

            <p class="font-weight-bold">Prestation de covering</p>

            <template v-for="referentiel_sticker in referentiel_stickers">
                <list-item-sticker-component
                    v-if="referentiel_sticker.category === 'covering'"
                    :key="referentiel_sticker.id_referentiel_sticker"
                    :max_orderable="3"
                    :referentiel_sticker="referentiel_sticker"
                    v-on:updateOrderReferentielSticker="updateOrderReferentielSticker"
                />
                <v-divider
                    v-if="referentiel_sticker.category === 'covering'"
                    :key="referentiel_sticker.uid"
                    class="mt-3 mb-3"
                    color="white"
                />
            </template>
        </template>

        <v-row v-else>
            <v-col v-for="n in 6" :key="n" cols="12">
                <v-skeleton-loader type="list-item-avatar-three-line" />
            </v-col>
        </v-row>

        <div class="d-flex justify-space-between gap-2">
            <div class="w-100">
                <v-alert v-if="isCoveringSelected" text type="info"> Le service communication vous recontactera </v-alert>
            </div>

            <div class="text-end">
                <span class="font-color-medium font-14">Total</span><br />
                <span class="font-14">{{ total_stickers }}</span>
            </div>
        </div>

        <v-btn :disabled="!valid" block class="mb-3" color="primary" elevation="0" large @click="$emit('goToStepTwo')">
            <span :class="!valid ? 'font-weight-bold' : 'secondary--text font-weight-bold'">Suivant</span>
        </v-btn>

        <v-btn block color="primary" text @click="navigateTo('stickers')">
            <span class="primary--text font-weight-bold">{{ $t('global.cancel') }}</span>
        </v-btn>
    </div>
</template>

<script>
import ListItemStickerComponent from '../ListItemStickerComponent.vue'
import ReferentielStickerRepository from '@/repositories/ReferentielStickerRepository'

export default {
    name: 'ListStickersComponent',
    components: { ListItemStickerComponent },
    data() {
        return {
            loading_referential_stickers: true,
            referentiel_stickers: [],
            stickers_to_order: [],
            total_vehicle_concerned: 0,
            total_stickers: 0,
        }
    },
    computed: {
        valid() {
            return this.total_stickers > 0 && this.total_vehicle_concerned > 0
        },
        isCoveringSelected() {
            let result = false
            this.referentiel_stickers.forEach((referentiel_sticker) => {
                if (referentiel_sticker.category === 'covering') {
                    this.stickers_to_order.filter((sticker_to_order) => {
                        if (sticker_to_order.id_referentiel_sticker === referentiel_sticker.id_referentiel_sticker) {
                            if (sticker_to_order.total_ordered > 0) {
                                result = true
                            }
                        }
                    })
                }
            })
            return result
        },
    },
    created() {
        this.getReferentielStickerByIdProfessional()
    },
    methods: {
        getReferentielStickerByIdProfessional() {
            ReferentielStickerRepository.getReferentielStickerByIdProfessional()
                .then((success) => {
                    this.referentiel_stickers = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.prepareOrderReferentielSticker()
                })
        },

        prepareOrderReferentielSticker() {
            this.referentiel_stickers.forEach((referentiel_sticker) => {
                this.stickers_to_order.push({
                    id_referentiel_sticker: referentiel_sticker.id_referentiel_sticker,
                    total_ordered: 0,
                })
            })
            this.loading_referential_stickers = false
        },

        updateOrderReferentielSticker(order_referentiel_sticker) {
            this.stickers_to_order.filter((sticker_to_order) => {
                if (sticker_to_order.id_referentiel_sticker === order_referentiel_sticker.id_referentiel_sticker) {
                    sticker_to_order.total_ordered = order_referentiel_sticker.total_ordered
                    this.updateTotalStickersToOrder()
                }
            })
        },

        updateTotalStickersToOrder() {
            this.total_stickers = 0
            this.stickers_to_order.forEach((sticker_to_order) => {
                this.total_stickers += sticker_to_order.total_ordered
            })
        },

        fixInput() {
            let v = this.total_vehicle_concerned
            if (!(!isNaN(parseFloat(v)) && v >= 0)) {
                this.total_vehicle_concerned = 0
            } else if (v > 99) {
                this.total_vehicle_concerned = 99
            }
        },
    },
}
</script>

<style>
.select-vehicle {
    max-width: 80px !important;
    position: absolute !important;
    right: 20px !important;
}
</style>
