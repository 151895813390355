<template>
    <div id="empty-page-component" class="empty-page-wrapper">
        <span class="h1-error">{{ main_text }}</span>
        <span v-if="show_secondary_text" class="font-14 font-color-medium">{{ default_secondary_text }}</span>
        <img :src="link_img" alt="empty page image" class="empty-page-image" />
    </div>
</template>

<script>
export default {
    name: 'EmptyPageComponent',
    props: {
        main_text: {
            type: String,
            required: true,
        },
        secondary_text: {
            type: String,
            required: false
        },
        show_secondary_text: {
            type: Boolean,
            required: false,
            default: false,
        },
        link_img: {
            type: String,
            required: true,
        },
    },

    computed: {
        default_secondary_text() {
            return this.secondary_text ? this.secondary_text : this.$t('empty_page_component.click_button_to_add')
        }
    }
}
</script>

<style>
.empty-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-inline: auto;
    max-width: 500px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.empty-page-image {
    max-width: 400px;
    width: 100%;
    margin-top: 12px;
}
</style>
