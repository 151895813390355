import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IOrderSticker } from '@/models/IOrderSticker'

const resource = 'api/v1/order_sticker'

export default {
    getOrderStickerByIdClientAndIdProfessional(): Promise<AxiosResponse<IServerResponse<IOrderSticker[]>>> {
        return axios.get(`${resource}`)
    },

    postOrderSticker(orderSticker): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}`, orderSticker)
    },
}
